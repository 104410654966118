.title {
  font-size: 16px;
  color: #3d5a80;
  margin: 0px 0px 20px 6px;
}

.reelsBox {
  padding: 15px;
}

.container {
  background-color: white;
}

.containerClass > button {
  background-color: white;
  color: black;
}

.reelsCard {
  position: relative;
  height: 440px;
  background: black !important;
  border-radius: 4px;
  justify-content: center;
  display: inline-block;
}

.reelsCard:hover .overlay {
  opacity: 1;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.reelsItems {
  border-radius: 4px;
  margin: 0px 3px;
}

.reelsThumbnail {
  border-radius: 4px;
  &:hover {
    opacity: 0.5;
    }
}
.reelsThumbnail:hover .overlay {
  opacity: 0.5;
}

.author {
  position: absolute;
  bottom: 10px;
  display: flex;
  gap: 5px;
  padding: 10px;
  align-items: center;
}

.authorName {
  font-size: 14px;
  color: white;
}

.reelsFlexHead {
  display: flex;
  align-items: center;
  grid-gap: 8px;
}
.lh-0 {
  line-height: 0;
}
.reelsFlexHead h5 {
  color: #5b5b5b;
}
