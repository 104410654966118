.datepicker {
  background: #f8f8f8;
  border: 1px solid #ddd;
  padding: 11px 35px 8px 12px;
  border-radius: 5px;
  width: 100%;
}
.datepickercalender {
  background: #f8f8f8;
  border: 1px solid #ddd;
  padding: 11px 20px;
  border-radius: 5px;
}
.attendeeAction {
  .deleteBtn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--chakra-colors-chakra-border-color);
  padding: 15px 0;
  position: relative;
  flex-direction: row;
  @media screen and (max-width:460px) {
    flex-direction: column;
  }
  .actionBtn {
    background: transparent;
    border-radius: 0;
    border: 0;
    color: #65676b;
    height: 36px;
    &:hover,
    &:active,
    &:focus {
      background: transparent;
      color: #65676b;
    }
  }
  .actionBtn + .actionBtn {
    border-left: 1px solid var(--chakra-colors-chakra-border-color);
    @media screen and (max-width:460px) {
      border: 0px;
    }
  }
}
.upcomingMeetBox {
  position: relative;
  display: block;
  padding: 16px 12px;

  .viewAllLink {
    position: absolute;
    right: 12px;
    top: 16px;
  }
  .upcomingMeetingCard {
    padding-bottom: 15px;
    border-bottom: 1px solid var(--chakra-colors-chakra-border-color);
    margin-bottom: 15px;
    display: grid;
    .meetingsLinksVisit{
        line-break: anywhere;
    }
    &:last-child{
        border: none;
        padding: 0px;
        margin-bottom: 0px;
    }
  }
}
.meetingPageBoxOuter{
    border-bottom: 1px solid #D9D9D9;
    &:last-child{
        border: none;
    }
}
.meetingPageBox{
    
    .innerBox{
        border-bottom: 1px solid #D9D9D9;
    }
    &:last-child{
        .innerBox{
            border-bottom: none;
        }
    }
}
.listingCheckbox {
  width:16px;
  height:16px;
  gap:0px;
  border-radius:4px;
  border:1px solid #3D5A80;
  display:flex;
  align-items:center;
  justify-content:center;
  padding:0;
  margin:0 2px;
}
