@media (max-width: 1300px) {
  .rightSidePannel {
    display: none;
  }
}
.rightSidePannel{
  color:'black'
}


@media (max-width: 1000px) {
  .leftZoomWidth {
    width: 165px;
    word-break: normal;
  }
}


@media (max-width: 800px) {
  .centerBox {
    width: 610px;
  }
}